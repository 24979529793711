import { API_URL } from "../App"

export async function getDateToLaunch() {
  try {
    const result = await fetch(`${API_URL}getLaunch`)
    const json = await result.json()
    return json.date
  } catch(e) {
    console.log(e)
    throw e
  }
}