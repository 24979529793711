import Countdown from 'react-countdown'
import { CircularProgress, Box, Typography } from '@mui/material'
import {useEffect, useState} from 'react'
import { getDateToLaunch } from '../services/date.service'

export default function Timer({setTimerComplete}) {
  const DATE_TO_CONSIDER_START = new Date('2023', '3', '5', '22')
  const [dateToComplete, setDateToComplete] = useState(null)

  useEffect(() => {
    getDateToLaunch().then((date) => {
      setDateToComplete(date)
    })
  }, [])

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setTimerComplete(true)
      return 
    } else {
      const total = Math.ceil(Math.abs(dateToComplete - DATE_TO_CONSIDER_START) / 1000)
      const progress = seconds + (minutes * 60) + (hours * 60 * 60)
      const progressPercentage = ((total - progress) / total) * 100

      return (
        <Box sx={{ position: 'relative'}} display="flex"  justifyContent='center' >
          {/* <CircularProgress size='90vw' thickness={2} variant="determinate" color="success" sx={{animationDuration: '90s'}} value={progressPercentage}/> */}
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              // position: 'absolute',
              marginTop: '15vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <img src="ball.png" style={{
          width: '90vw',
          position: 'absolute',
          left: '11vw',
          zIndex: -1
        }}></img> */}
            <Typography
              variant="h3"
              component="div"
              color="text.primary"
              fontWeight="bold"
            >{(hours !== 0 || minutes !== 0) ? `${hours} : ${minutes} : ${seconds}` : `${seconds}`}</Typography>
          </Box>
        </Box>
      )
    }
  };

  if (!dateToComplete) {
    return 
  }

  return (
    <Countdown
      date={dateToComplete  }
      renderer={renderer}
    />
  )
}
