import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import DiscoDiva from './DiscoDiva.woff2'
import { useState } from 'react'
import './App.css';
import Timer from './components/timer'
import Info from './components/Info';
import Camera from './components/camera';
import Sparkles from './components/sparkles';

export const API_URL = "https://lapuzs4rf1.execute-api.ap-southeast-2.amazonaws.com/dev/"
// export const API_URL = ""

const theme = createTheme({
  typography: {
    fontFamily: "DiscoDiva",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'DiscoDiva';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('DiscoDiva'), local('DiscoDiva'), url(${DiscoDiva}) format('woff2');
        }
      `,
    },
  },
  palette: {
    text: {
      primary: "#FFFFFF",
    },
    background: {
      default: "#ed1f24"
    },
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#ed1f24"
    },
    success: {
      main: "#FFFFFF"
    }
  } 
});

// theme.typography.h3 = {
//   fontSize: '2rem',
//   '@media (min-width:350px)': {
//     fontSize: '3rem',
//   },
//   '@media (min-width:500px)': {
//     fontSize: '4rem',
//   },
// };

window.location.replace("https://linktr.ee/succulentfunk");

function App() {
  const [timerComplete, setTimerComplete] = useState(false)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline></CssBaseline>
      <Box sx={{width: 1}}>
        {/* <Header></Header> */}
        <Info></Info>
        {timerComplete ? <Camera /> : <Timer setTimerComplete={setTimerComplete} />}
        <img src="red_carpet.png" style={{
          width: '100vw',
          position: 'absolute',
          bottom: '-10vh',
          zIndex: -2
        }}>
        </img>
        <Sparkles />
      </Box>
    </ThemeProvider>
  );
}

export default App;
