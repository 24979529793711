import { API_URL } from "../App"

export async function uploadImage(image) {
  try {
    const res = await fetch(`${API_URL}signedURL?name=${image.name}&type=${image.type}`, {
      method: "GET",
    })
    const { url } = await res.json()
    fetch(url, {
      method: "PUT",
      body: image,
      headers: {
        'Access-Control-Allow-Origin':'*',
      }
    })
} catch (e) {
  console.log(e)
  throw e
}
}