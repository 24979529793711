import {animated, useSprings} from '@react-spring/web'
import { Box } from '@mui/system'
export default function Sparkles() {
  const posMap = [
    {
      top: 0,
      left: 0
    },
    {
      top: '55vh',
      left: '7vw'
    },
    {
      top: '26vh',
      left: '75vw',
    },
    {
      top: '60vh',
      left: '82vw'
    }
  ]
  const [springs] = useSprings(4, (i) => {
    const rotate = i % 2 === 0 ? 45 : -45
    if (i === 1) {
      return {
        from: {width: '20vw', rotate: 0},
        to: [{width: '25vw', rotate: rotate}, {width: '20vw', rotate: 0}],
        loop: true,
        delay: i * 100
      }
    }
    return {
      from: {width: '10vw', rotate: 0},
      to: [{width: '15vw', rotate: rotate}, {width: '10vw', rotate: 0}],
      loop: true,
      delay: i * 100
    }
  })
  return (
    <Box position={'absolute'} height='100vh' width='100vw' top='0' style={{
      zIndex: -2,
    }}>
      {springs.map((props, i) => {
        return <animated.img key={i} src="sparkle.png" style={{
          position: 'absolute',
          zIndex: -2,
          width: '100vw',
          top: posMap[i].top,
          left: posMap[i].left,
          ...props
        }}/>
      })}
      
    </Box>
  )
}