import { Box, Divider, Typography } from '@mui/material'
export default function Info() {
  return (
    <Box marginBottom={'3vh'}>
      <Typography marginTop='3vh' color={"textPrimary"} variant="body2" textAlign={'center'} fontFamily='DiscoDiva' fontWeight={'bold'}>
        succulent funk presents
      </Typography>
      <Typography marginTop='2vh' color={"textPrimary"} variant="h4" textAlign={'center'} fontFamily='DiscoDiva' fontWeight={'bold'}>
        Disc<Typography fontWeight='regular' variant='p'>o</Typography>theque
      </Typography>
      <Divider sx={{borderColor: 'white', marginTop: '2vh'}} variant='middle'></Divider>
      <Typography marginTop='3vh' color={"textPrimary"} variant="body2" textAlign={'center'} width='1' fontFamily={'DiscoDiva'} fontWeight='bold'>
        THE COUNTDOWN IS ON.
        <br />
        SOMETHING SPECIAL WILL HAPPEN AT 1:00AM.
      </Typography>
    </Box>
  )
}