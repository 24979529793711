import { useState, useEffect } from "react";
import { uploadImage } from "../services/image.service";
import { Box } from "@mui/system";
import { Typography, Button, Input } from "@mui/material";
import { PhotoCamera } from '@mui/icons-material'

const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"]

export default function Camera() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageError, setImageError] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    if (!selectedImage) {
      return
    }
    if (selectedImage.size >= 2e+6) {
      setImageError("Image too big!")
      return
    }
    if (!ALLOWED_FILE_TYPES.includes(selectedImage.type)) {
      setImageError("Please CHOOSE an image")
      return
    }
    setIsUploading(true)
    uploadImage(selectedImage).then(() => {
      setIsUploading(false)
    }).catch(e => {
      setIsUploading(false)
      setImageError("error uploading image")
    })

  }, [selectedImage])
  return (
    <Box display='flex' justifyContent={'center'} marginTop='15vw' flexWrap='wrap'>
      {imageError ? <Typography fontWeight={'bold'} textAlign='center' flexBasis='100%'>`${imageError}`</Typography> : null}
      <Typography fontWeight={'bold'} textAlign='center' flexBasis='100%' variant="body2">
        PLEASE UPLOAD AN IMAGE AND SHARE YOUR EXPERIENCE OF THE EVENING.
      </Typography>
      {isUploading ? null :
        <Button sx={{ marginTop: '5vh' }} variant="contained" startIcon={<PhotoCamera color="secondary" />} component="label" disableElevation={true} >
          <Typography fontWeight={'bold'} color="secondary" position={'relative'} top={'.4vh'}>UPLOAD</Typography>
          <input hidden accept="image/*" type="file" name="image" onChange={(event) => {
            setSelectedImage(event.target.files[0]);
          }} />
        </Button>
      }

    </Box>
  )
}
